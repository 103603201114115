import React from "react"
import styled from "styled-components"
import PageTemplate from "../components/pageTemplate"
import { Table } from "reactstrap"
import Hr from "../components/hr"
import RedAlert from "../components/alert"

let StyledTable = styled(Table)`
  td {
    font-weight: 300;
    vertical-align: top;
    padding: 5px 2px;
  }
  th {
    vertical-align: top;
    padding: 5px 32px 5px 2px;
  }
  tr:hover {
    td,
    th {
      background: #eee;
    }
  }
`

let Ordinationszeiten = () => (
  <PageTemplate title="Ordinationszeiten">
    <RedAlert>
      <h5>Folgende Punkte sind während der Corona-Krise zu beachten:</h5>
      <ul>
        <li>
          Es besteht nun auch für Patienten eine Tragepflicht von
          Mund-Nasenschutz (Maske, Schal oder Tuch) in den Ordinationen.
        </li>
        <li>
          Bei Betreten und Verlassen sind die Hände zu waschen und zu
          desinfizieren!
        </li>
        <li>Sicherheitsabstand von mind. 1 m zu Personal und Mitpatienten</li>
        <li>Telefonische Voranmeldung ist notwendig!</li>
      </ul>
    </RedAlert>
    <Hr />
    <StyledTable>
      <tbody>
        <tr>
          <th scope="row">Montag</th>
          <td>07.30 - 11.30 Uhr</td>
        </tr>
        <tr>
          <th scope="row">Dienstag</th>
          <td>
            07.30 - 10.00 Uhr und 14.00 - 15.30 Uhr <br />
            (mit Sprechstunde)<br />
            <small>Journaldienst 10.00 - 11:30 Uhr<br />Visitentätigkeit 10.00 - 14.00 Uhr</small>
          </td>
        </tr>
        <tr>
          <th scope="row">Mittwoch</th>
          <td>07.30 - 11.30 Uhr</td>
        </tr>
        <tr>
          <th scope="row">Donnerstag</th>
          <td>13.00 - 16.00 Uhr</td>
        </tr>
        <tr>
          <th scope="row">Freitag</th>
          <td>07.30 - 11.30 Uhr</td>
        </tr>
      </tbody>
    </StyledTable>
    <Hr />
    <p className="bodytext">
      Als erweitertes Service vor allem für unsere Berufstätigen und Patienten
      mit Mobilitätseinschränkungen bieten wir auch{" "}
      <strong>nach Ablauf der Coronamassnahmen</strong> weiterhin Folgendes an:
    </p>
    <ul>
      <li>
        <strong>Vorbestellungen</strong> von Rezepten, Überweisungs,-
        Verordnungsscheinen (Per Email auf
        <br />
        <a href={`mailto:anmeldung@dr-joerg.at}`}>anmeldung@dr-joerg.at</a> oder
        telefonisch 03119/21000 zwischen 10:30 und 11:30,
        <br />
        Abholung bzw. Übermittlung nach individueller Vereinbarung)
      </li>
      <li>
        <strong>Telefonische Besprechungen</strong> von einfachen
        Laborergebnissen und Befunden
      </li>
      <li>
        <strong>Konsultationen via elektronischer Medien</strong> oder Telefon
        nach Voranmeldung
      </li>
    </ul>
    <RedAlert dark={true}>
      <p>
        <strong>NEU:</strong>
      </p>
      <ul>
        <li>Corona-Virus Diagnostik</li>
        <li>Antigen-Schnellstest (Ergebnis in 20 Minuten)</li>
        <li>
          PCR-Test-Abnahme{" "}
          <strong>nach telefonischer Voranmeldung möglich</strong>
        </li>
        <li>Antikörper Schnelltest</li>
      </ul>
    </RedAlert>
  </PageTemplate>
)

export default Ordinationszeiten
